export default [
    {
        path: "/authorizationLoading",
        name: "authorizationLoading",
        component: () => import("@/views/share/authorization/index.vue"),
        meta: { title: '加载页面', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: "/identity",
        name: "identity",
        component: () => import("@/views/share/preAudit/identity/index.vue"),
        meta: { title: '身份认证', returnBtn: false, module: 'share' }
    },
    {
        path: "/identityLoading",
        name: "identityLoading",
        component: () => import("@/views/share/preAudit/identity/loading.vue"),
        meta: { title: '身份认证加载页面', returnBtn: false, module: 'share' }
    },
    {
        path: "/driver",
        name: "driver",
        component: () => import("@/views/share/preAudit/driver/index.vue"),
        meta: { title: '车辆信息', returnBtn: false, module: 'share' }
    },
    {
        path: "/driverLoading",
        name: "driverLoading",
        component: () => import("@/views/share/preAudit/driver/loading.vue"),
        meta: { title: '车来呗', returnBtn: false, module: 'share' }
    },
    {
        path: "/preAmount",
        name: "preAmount",
        component: () => import("@/views/share/beforeAudit/preAmount/index.vue"),
        meta: { title: '预审额度', returnBtn: false, module: 'share' }
    },
    {
        path: "/bankCard",
        name: "bankCard",
        component: () => import("@/views/share/beforeAudit/bankCard/index.vue"),
        meta: { title: '银行卡信息', returnBtn: true, module: 'share', keepAlive: true }
    },
    {
        path: "/baseInfo",
        name: "baseInfo",
        component: () => import("@/views/share/beforeAudit/baseInfo/index.vue"),
        meta: { title: '基本信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/workInfo",
        name: "workInfo",
        component: () => import("@/views/share/beforeAudit/workInfo/index.vue"),
        meta: { title: '工作信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/proofOfIncome",
        name: "proofOfIncome",
        component: () => import("@/views/share/beforeAudit/proofOfIncome/index.vue"),
        meta: { title: '流水证明', returnBtn: true, module: 'share' }
    },
    {
        path: "/registerCard",
        name: "registerCard",
        component: () => import("@/views/share/beforeAudit/registerCard/index.vue"),
        meta: { title: '登记证书', returnBtn: true, module: 'share' }
    },
    {
        path: '/signing',
        component: () => import("@/views/share/beforeAudit/signing/index.vue"),
        name: 'signing',
        meta: { title: '签约授权', returnBtn: false, module: 'share' }
    },
    {
        path: '/orderLoading',
        component: () => import("@/views/share/beforeAudit/signing/orderLoading.vue"),
        name: 'orderLoading',
        meta: { title: '签约授权', returnBtn: false, module: 'share' }
    },
    {
        path: '/signingProtocol',
        component: () => import("@/views/share/beforeAudit/signing/protocol.vue"),
        name: 'signingProtocol',
        meta: { title: '车来呗', returnBtn: true, module: 'share' }
    },
    {
        path: '/signingLoading',
        component: () => import("@/views/share/beforeAudit/signing/loading.vue"),
        name: 'signingLoading',
        meta: { title: '车来呗', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: '/signingHaoYunLoading',
        component: () => import("@/views/share/beforeAudit/signing/haoyunLoading.vue"),
        name: 'signingHaoYunLoading',
        meta: { title: '车来呗', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: '/personRecordingAgain',
        component: () => import("@/views/share/recordingAgain/index.vue"),
        name: 'personRecordingAgain',
        meta: { title: '信息补录', returnBtn: false }
    },
    {
        path: "/reviewPage",
        name: "reviewPage",
        component: () => import("@/views/share/afterAudit/reviewPage/index.vue"),
        meta: { title: '额度审批', returnBtn: false, module: 'share' }
    },
    {
        path: "/driverCardInfo",
        name: "driverCardInfo",
        component: () => import("@/views/share/afterAudit/driverCardInfo/index.vue"),
        meta: { title: '车辆补充信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/appendixInfo",
        name: "appendixInfo",
        component: () => import("@/views/share/afterAudit/appendixInfo/index.vue"),
        meta: { title: '证明材料', returnBtn: true, module: 'share' }
    },
    {
        path: "/appendixInfoUpload",
        name: "appendixInfoUpload",
        component: () => import("@/views/share/afterAudit/appendixInfo/upload.vue"),
        meta: { title: '证明材料', returnBtn: true, module: 'share' }
    },
    {
        path: "/companyinfo",
        name: "companyinfo",
        component: () => import("@/views/share/afterAudit/appendixInfo/companyinfo.vue"),
        meta: { title: '营业执照', returnBtn: true, module: 'share' }
    },
    {
        path: "/spouseBasic",
        name: "spouseBasic",
        component: () => import("@/views/share/afterAudit/spouseBasic/index.vue"),
        meta: { title: '配偶基本信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/spouseWork",
        name: "spouseWork",
        component: () => import("@/views/share/afterAudit/spouseWork/index.vue"),
        meta: { title: '配偶工作信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/basicPage",
        name: "basicPage",
        component: () => import("@/views/share/afterAudit/basicPage/index.vue"),
        meta: { title: '基本信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/personContract",
        name: "personContract",
        component: () => import("@/views/share/contractSigning/index.vue"),
        meta: { title: '确认拿钱', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: "/contractSigning",
        name: "contractSigning",
        component: () => import("@/views/share/contractSigning/signing.vue"),
        meta: { title: '签署合同', returnBtn: true, module: 'share', keepAlive: true }
    },
    {
        path: '/personHaoyunLoading',
        name: 'personHaoyunLoading',
        component: () => import("@/views/share/contractSigning/haoyunLoading.vue"),
        meta: { title: '合同签署加载', returnBtn: false }
    },
    {
        path: '/contractFinish',
        name: 'contractFinish',
        component: () => import("@/views/share/contractSigning/finish.vue"),
        meta: { title: '合同签署-完成', returnBtn: false }
    },
    {
        path: '/dealingBreachProtocol',
        name: 'dealingBreachProtocol',
        component: () => import("@/views/share/dealingBreach/index.vue"),
        meta: { title: '三方协议', returnBtn: false, keepAlive: true }
    },
    {
        path: '/rentalContractSigningResult',
        component: () => import("@/views/share/fddResult/index.vue"),
        name: 'rentalContractSigningResult',
        meta: { title: '合同签署', returnBtn: false }
    },
    {
        path: '/dealingBreachAuctionAuthorization',
        name: 'dealingBreachAuctionAuthorization',
        component: () => import("@/views/share/dealingBreach/auctionAuthorization.vue"),
        meta: { title: '三方协议', returnBtn: false }
    },
    {
        path: '/dealingBreachAuctionAuthorizationOk',
        name: 'dealingBreachAuctionAuthorizationOk',
        component: () => import("@/views/share/dealingBreach//auctionAuthorizationOk.vue"),
        meta: { title: '三方协议', returnBtn: false }
    },
    {
        path: "/noAuthority",
        name: "noAuthority",
        component: () => import("@/views/share/noAuthority/index.vue"),
        meta: { title: '车来呗', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: "/shareError",
        name: "shareError",
        component: () => import("@/views/share/error/index.vue"),
        meta: { title: '车来呗', returnBtn: false, module: 'share', noAuthority: true }
    },
    {
        path: '/personSuccess',
        component: () => import('@/views/share/success/loading.vue'),
        name: 'personSuccess',
        meta: { title: '车来呗', returnBtn: false }
    },
]