import { Toast } from 'vant';

// 全局加载动画

export const loadingFu = (duration = 0, text: any) => {
    const loading = Toast.loading({
        message: text || '加载中...',
        duration, // 持续展示 toast
        forbidClick: true,
        getContainer: "#app",
        className: 'z_index'
    });
    return loading
}

// ---------------------------------数据验证
//常用正则
export const checkStr = (str: string, type = 0) => {
    let reg: any
    if (str) {
        switch (type) {
            case 0:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^1\d{10}$/); //手机号码验证
                break;
            case 1:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[1-9]\d{5}$/); //邮政编码验证
                break;
            case 2:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/); //匹配EMAIL
                break;
            case 3:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/(^\d{18}$)|(^\d{17}(\d|X|x)$)/); //匹配身份证
                break;
            case 4:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^\d+$/); //是否为0-9的数字
                break;
            case 5:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[a-zA-Z\u0391-\uFFE5]*[\w\u0391-\uFFE5]*$/); //不能以数字或符号开头
                break;
            case 6:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^\w+$/); //匹配由数字、26个英文字母或者下划线组成的字符串
                break;
            case 7:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[\u0391-\uFFE5]+$/); //匹配中文
                break;
            case 8:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[a-zA-Z\u0391-\uFFE5]+$/); //不能包含数字和符号
                break;
            case 9:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^\d{6}$/); //6位验证码验证
                break;
            case 10:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^\d{4}$/); //4位验证码验证
                break;
            case 11:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[HMhm]\d{8}$/); //港澳通行证
                break;
            case 12:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/((?=.*[0-9]))(^[A-Za-z0-9]{8}$)/); //台湾通行证 必须包含数字；字母数字 共8位
                break;
            case 13:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(
                    /(^([a-zA-Z]+\s)*[a-zA-Z]+$)|(^[\u4e00-\u9fa5]+$)/); //JS 限制用户名只能中文、字母和数字 ， 不能包含特殊字符
                break;
            case 14:
                // eslint-disable-next-line prefer-regex-literals
                reg = new RegExp(/^[a-z0-9]+$/i); //只能是数字和字母
                break;
        } //end switch
        if (reg.test(str)) {
            return true;
        }
        return false;
    } else {
        return false;
    }
} //end func

/**
 *
 * @returns 判断当前环境是ios还是安卓
 */
export const phoneOs = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isAndroid) {
        return 'Android'
    }
    if (isiOS) {
        return 'IOS'
    }
    return ''
}

/**
 *
 * @param {回调函数} callback
 * 监听页面是否隐藏跟显示
 */
// export const listeningPage = (callback) => {
//     document.addEventListener('visibilitychange', () => {
//         console.log('监听页面返回----', document.hidden);
//         if (!document.hidden) { // 页面隐藏
//             console.log('页面显示');
//             callback()
//         } else {
//             console.log('页面隐藏');
//         }
//     }, false)
// }

/**
 * 清楚监听页面显示跟隐藏方法
 */

// export const cancelListening = () => {
//     document.addEventListener('visibilitychange', (event) => {
//         event.stopImmediatePropagation();
//     }, true)
// }

/**
 * 时间日期格式化
 * @param format
 * @returns {*}
 */
export const dateFormat = (dateObj: any, format: any): any => {
    const date: any = {
        'M+': dateObj.getMonth() + 1,
        'd+': dateObj.getDate(),
        'h+': dateObj.getHours(),
        'm+': dateObj.getMinutes(),
        's+': dateObj.getSeconds(),
        'q+': Math.floor((dateObj.getMonth() + 3) / 3),
        'S+': dateObj.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
        format = format.replace(RegExp.$1, (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in date) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1
                ? date[k]
                : ('00' + date[k]).substr(('' + date[k]).length))
        }
    }
    return format
}

/**
 *  设置对象拼接
 */
export const splicingObjects = (obj: { [x: string]: any; }) => {
    const arr = []
    for (const k in obj) {
        if (obj[k] instanceof Array) {
            arr.push(`${k}=${obj[k].join(",")}`)
        } else {
            arr.push(`${k}=${obj[k]}`)
        }
    }
    return arr.join('&')
}
/**
 *  判断是否超过30分钟
 */
export const judgeTimeFu = (time: number) => {
    const time1 = new Date().getTime();
    return Math.abs(time1 - time) >= (30 * 60 * 1000)
}

/**
 * 将数组指定元素移到尾部
 */
export const moveToBack = (arr: any[], elementToMove: any) => {
    const index = arr.indexOf(elementToMove);
    if (index !== -1) {
        arr.splice(index, 1); // 删除指定元素
        arr.push(elementToMove); // 将指定元素添加到数组末尾
    }
}

/**
 * 获取设备信息
 */
export const getOperatingDetail = () => {
    return {
        // osPlatformNo: getOsPlatformNo(),
        osPlatform: 'web',
        deviceType: 'web',
        // currPgTitl: router.history.current.meta.title,
        currPgUrl: window.location.href.split('?')[0]
    };
}

/**
 * 字符串截取
 */
export const urlSplitFu = (url: string) => {
    if (url.indexOf(".com/") > -1) {
        let str = url.split(".com/")[1];
        if (str.indexOf("?") > -1) {
            str = str.split("?")[0];
        }
        return str
    } else {
        return url
    }
}

// export const getOsPlatformNo = () => {
//     const { userAgent } = navigator;
//     let version
//     if (/Windows NT 10.0/.test(userAgent)) {
//         version = "Windows 10";
//     } else if (/Windows NT 6.2/.test(userAgent)) {
//         version = "Windows 8";
//     } else if (/Windows NT 6.1/.test(userAgent)) {
//         version = "Windows 7";
//     } else if (/Mac OS X ([\.\_\d]+)/.test(userAgent)) {
//         version = "Mac OS X " + userAgent.match(/Mac OS X ([\.\_\d]+)/)[1];
//     } else if (/Android ([\.\_\d]+)/.test(userAgent)) {
//         version = "Android " + userAgent.match(/Android ([\.\_\d]+)/)[1];
//     } else if (/iPhone OS ([\.\_\d]+)/.test(userAgent)) {
//         version = "iOS " + userAgent.match(/iPhone OS ([\.\_\d]+)/)[1].replace(/_/g, '.');
//     }
//     return version;
// }
