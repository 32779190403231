export default [
    {
        path: "/authorizationIdentity",
        name: "authorizationIdentity",
        component: () => import("@/views/monthlyRent/preAudit/identity/index.vue"),
        meta: { title: '身份认证', returnBtn: false, module: 'share' }
    },
    {
        path: "/identityLoadingMonthly",
        name: "identityLoadingMonthly",
        component: () => import("@/views/monthlyRent/preAudit/identity/loading.vue"),
        meta: { title: '身份认证加载页面', returnBtn: false, module: 'share' }
    },
    {
        path: "/driverMonthly",
        name: "driverMonthly",
        component: () => import("@/views/monthlyRent/preAudit/driver/index.vue"),
        meta: { title: '车辆信息', returnBtn: false, module: 'share' }
    },
    {
        path: "/driverLoadingMonthly",
        name: "driverLoadingMonthly",
        component: () => import("@/views/monthlyRent/preAudit/driver/loading.vue"),
        meta: { title: '车来呗', returnBtn: false, module: 'share' }
    },
    {
        path: "/preAmountMonthly",
        name: "preAmountMonthly",
        component: () => import("@/views/monthlyRent/beforeAudit/preAmount/index.vue"),
        meta: { title: '车辆估值', returnBtn: false, module: 'share' }
    },
    {
        path: "/bankCardMonthly",
        name: "bankCardMonthly",
        component: () => import("@/views/monthlyRent/beforeAudit/bankCard/index.vue"),
        meta: { title: '银行卡信息', returnBtn: true, module: 'share', keepAlive: true }
    },
    {
        path: "/baseInfoMonthly",
        name: "baseInfoMonthly",
        component: () => import("@/views/monthlyRent/beforeAudit/baseInfo/index.vue"),
        meta: { title: '基本信息', returnBtn: true, module: 'share' }
    },
    {
        path: "/workInfoMonthly",
        name: "workInfoMonthly",
        component: () => import("@/views/monthlyRent/beforeAudit/workInfo/index.vue"),
        meta: { title: '工作信息', returnBtn: true, module: 'share' }
    },
    // {
    //     path: '/personRecordingAgain',
    //     component: () => import("@/views/monthlyRent/recordingAgain/index.vue"),
    //     name: 'personRecordingAgain',
    //     meta: { title: '信息补录', returnBtn: false }
    // },
    {
        path: '/signingMonthly',
        component: () => import("@/views/monthlyRent/afterAudit/signing/index.vue"),
        name: 'signingMonthly',
        meta: { title: '签约授权', returnBtn: false, keepAlive: true, module: 'share' }
    },
    {
        path: '/orderLoadingMonthly',
        component: () => import("@/views/monthlyRent/afterAudit/signing/orderLoading.vue"),
        name: 'orderLoadingMonthly',
        meta: { title: '签约授权', returnBtn: false, module: 'share' }
    },
    {
        path: '/signingLoadingMonthly',
        component: () => import("@/views/monthlyRent/afterAudit/signing/loading.vue"),
        name: 'signingLoadingMonthly',
        meta: { title: '车来呗', returnBtn: false, module: 'share' }
    },
    {
        path: '/signingHaoYunLoadingMonthly',
        component: () => import("@/views/monthlyRent/afterAudit/signing/haoyunLoading.vue"),
        name: 'signingHaoYunLoadingMonthly',
        meta: { title: '车来呗', returnBtn: false, module: 'share' }
    },
    {
        path: "/driverCardInfoMonthly",
        name: "driverCardInfoMonthly",
        component: () => import("@/views/monthlyRent/afterAudit/driverCardInfo/index.vue"),
        meta: { title: '车辆补充信息', returnBtn: true, module: 'monthlyRent' }
    },
    {
        path: '/changeCardMonthly',
        component: () => import("@/views/monthlyRent/changeCard/index.vue"),
        name: 'changeCardMonthly',
        meta: { title: '更换银行卡', returnBtn: true, keepAlive: true, module: 'share' }
    },
    {
        path: '/leaseContractMonthly',
        component: () => import("@/views/monthlyRent/leaseContract/index.vue"),
        name: 'leaseContractMonthly',
        meta: { title: '租赁合同', returnBtn: false, module: 'share' }
    },
    {
        path: '/leaseContractProtocolList',
        component: () => import("@/views/monthlyRent/leaseContract/protocolList.vue"),
        name: 'leaseContractProtocolList',
        meta: { title: '合同签署', returnBtn: true, module: 'share', keepAlive: true }
    },
    {
        path: '/leaseContractLoading',
        component: () => import("@/views/monthlyRent/leaseContract/loading.vue"),
        name: 'leaseContractLoading',
        meta: { title: '合同签署', returnBtn: false, module: 'share' }
    },
    {
        path: '/rentalSuccess',
        component: () => import("@/views/monthlyRent/success/index.vue"),
        name: 'rentalSuccess',
        meta: { title: '月月租', returnBtn: false }
    },
    {
        path: '/vehicleTransferTips',
        component: () => import("@/views/monthlyRent/success/vehicleTransfer.vue"),
        name: 'vehicleTransferTips',
        meta: { title: '车辆过户', returnBtn: false }
    },
    {
        path: '/feePaymentMonthly',
        component: () => import("@/views/monthlyRent/feePayment/index.vue"),
        name: 'feePaymentMonthly',
        meta: { title: '费用支付', returnBtn: false }
    },
    {
        path: '/feePaymentAutoMonthly',
        component: () => import("@/views/monthlyRent/feePayment/auto.vue"),
        name: 'feePaymentAutoMonthly',
        meta: { title: '费用支付', returnBtn: false }
    },
    {
        path: '/feePaymentAddDoneMonthly',
        component: () => import("@/views/monthlyRent/feePayment/done.vue"),
        name: 'feePaymentAddDoneMonthly',
        meta: { title: '费用支付', returnBtn: false }
    }
]