import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { showToast, setToastDefaultOptions, closeToast } from 'vant';
import { useUserStore } from '@/store/modules/user';
setToastDefaultOptions({ duration: 2000 });
setToastDefaultOptions({ forbidClick: true });
setToastDefaultOptions({ className: 'z_index' });

class HttpRequest {
    private readonly baseUrl: string;
    constructor() {
        this.baseUrl = ''
    }
    getInsideConfig() {
        const config = {
            baseURL: '',// 所有的请求地址前缀部分(没有后端请求不用写)  
            timeout: 20000, // 请求超时时间(毫秒)
            withCredentials: true,// 异步请求携带cookie
            // headers: {
            // 设置后端需要的传参类型
            // 'Content-Type': 'application/json',
            // 'token': x-auth-token',//一开始就要token
            // 'X-Requested-With': 'XMLHttpRequest',
            // },
        }
        return config
    }

    // 请求拦截
    interceptors(instance: AxiosInstance, url: string | number | undefined) {
        const useUser = useUserStore()
        instance.interceptors.request.use(config => {
            config.headers.token = useUser.token;
            config.headers.lang = "zh-CN"
            config.headers.merchantCode = process.env.VUE_APP_MERCHANT_CODE
            config.headers.appCode = process.env.VUE_APP_APP_CODE
            config.headers.vestCode = process.env.VUE_APP_VEST_CODE
            config.headers.secretCode = process.env.VUE_APP_SECRET_CODE
            config.headers.countryCode = 'China'
            config.headers.country = '86'
            // config.headers['merchantCode'] = merchantCode;
            config.headers.apiVersion = 'v1'
            config.headers.platform = 'web'
            config.headers.clientType = 'carOperator'
            return config
        }, (error: any) => {
            return Promise.reject(error)
        })
        //响应拦截
        instance.interceptors.response.use((res: any) => {
            //返回数据
            const { data } = res
            const { code } = data
            // 访问受限没有token返回到首页
            if (code == 'FM000003' || code == 'FM000001') {
                useUser.setNotLoggedIn(true)
                return data
            }
            return data
        }, (error: any) => {
            if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
                error.mesg = '请求超时，请稍后再试'
                closeToast()
                showToast(error.mesg);
                return error
            } else {
                error.mesg('网络错误，请稍后再试')
            }
            closeToast()
            showToast(error.mesg);
            return error
        })
    }
    request(options: AxiosRequestConfig) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

const http = new HttpRequest()
export default http