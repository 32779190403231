import { defineStore } from "pinia";
export const useShareStore = defineStore('shareData', () => {
  const shareToken = ref('');
  const groupStatus = ref(''); // 判断当前认证阶段
  const orderType = ref(''); //  判断是月月租还是车贷
  const preSetps = ref({}); // 前置审核头部数据
  const thirdCode = ref(''); // 三方认证方法
  const loanAuthStatus = ref({}); // 页面需要展示元素
  const preAmount = ref(''); // 预审金额选择
  const protocolList = ref([]); // 协议列表
  const yyzState = ref('');
  const setShareToken = data => {
    shareToken.value = data;
  };
  const setGroupStatus = data => {
    groupStatus.value = data;
  };
  const setOrderType = data => {
    orderType.value = data;
  };
  const setPreSetps = data => {
    preSetps.value = data;
  };
  const setThirdCode = data => {
    thirdCode.value = data;
  };
  const setLoanAuthStatus = data => {
    loanAuthStatus.value = data;
  };
  const setPreAmount = data => {
    preAmount.value = data;
  };
  const setProtocolList = data => {
    protocolList.value = data;
  };
  const setYyzState = data => {
    yyzState.value = data;
  };
  const resetFu = () => {
    shareToken.value = '';
    groupStatus.value = '';
    orderType.value = '';
    preSetps.value = {};
    thirdCode.value = '';
    loanAuthStatus.value = {};
    preAmount.value = '';
    protocolList.value = [];
    yyzState.value = '';
  };
  return {
    shareToken,
    setShareToken,
    groupStatus,
    setGroupStatus,
    orderType,
    setOrderType,
    preSetps,
    setPreSetps,
    thirdCode,
    setThirdCode,
    loanAuthStatus,
    setLoanAuthStatus,
    preAmount,
    setPreAmount,
    protocolList,
    setProtocolList,
    yyzState,
    setYyzState,
    resetFu
  };
}, {
  persist: true
});