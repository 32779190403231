import fetch from '../system';


/**
 * 登录接口
*/
export function loginApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/system/auth/login',
        method: 'post',
        data: data
    });
}

/**
 * 取得我的信息
 */

export function selectByMeApi() {
    return fetch.request({
        url: '/system_v/api/loan/system/user/selectByMe',
        method: 'get',
    });
}

/**
 * 根据用户id获取当前站点信息
 */
export function findOrgByUserIdApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/system/org/findOrgByUserId`,
        method: 'post',
        params
    });
}

/**
 * 分页查询业务员的业务单
 */

export function pageSalesOrderApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/debt/salesOrder/pageSalesOrder',
        method: 'post',
        data
    });
}

/**
 * 业务单状态变更操作
 */
export function processApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/process`,
        method: 'post',
        params
    });
}


/**
 * 业务员端基于原有业务单选择产品后创建业务单
 */

export function createSalesOrderBySourceApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/createSalesOrderBySource`,
        method: 'post',
        params
    });
}

/**
 * 查看配置参数
 */

export function findByGroupAndKeyPublicApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/system/config/findByGroupAndKeyPublic`,
        method: 'get',
        params
    });
}

/**
 * 业务单详情
 */

export function salesOrderDetailApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/salesOrderDetail`,
        method: 'post',
        params
    });
}

/**
 * 获取业务单流程步骤数据
 */

export function querySalesOrderProcessStepApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/querySalesOrderProcessStep`,
        method: 'post',
        params
    });
}

/**
 * 查询车务办理详情
 */

export function vehicleDetailApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/detail`,
        method: 'get',
        params
    });
}

/**
 * 查询车务办理列表
 */
export function getVehicleListApi(data: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/list`,
        method: 'post',
        data: data
    });
}

/**
 * 获取抵押合同文件地址
 */

export function getMortgageProtocolUrlApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/contract/getMortgageProtocolUrl`,
        method: 'get',
        params
    });
}

/**
 * 获取OSS STS临时认证
 */

export function getStsTokenSystemApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/system/sts/getStsToken`,
        method: 'get',
        params
    });
}

/**
 * 省份、市区、县区区域树列表查询
 */

export function getRegionCountyTreeApi() {
    return fetch.request({
        url: '/system_v/api/loan/app/region/getRegionCountyTree',
        method: 'get',
    });
}

/**
 *   获取短信验证码
 */
export function getSmsCodeApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/user/getSmsCode',
        method: 'post',
        data
    });
}

/**
 * 获取图形验证码
 */

export function getImgCodeApi() {
    return fetch.request({
        url: `/system_v/api/loan/user/getImgCode`,
        method: 'get'
    });
}

/**
 * 录入客户信息
 */

export function addUserApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/user/addUser',
        method: 'post',
        data
    });
}

/**
 * 获取分享二维码地址
 */

export function getInviteInfoApi() {
    return fetch.request({
        url: `/system_v/api/loan/system/user/getInviteInfo`,
        method: 'post',
    });
}

/**
 * 通用协助办理页面接口
 */

export function sharedCommonApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/sharedCommon`,
        method: 'post',
        params
    });
}

/**
 * 修改用户密码
 */

export function updatePassWordApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/system/auth/updatePassWord',
        method: 'put',
        data
    });
}

/**
 * 退出登录接口
 */

export function logoutApi() {
    return fetch.request({
        url: '/system_v/api/loan/system/auth/logout',
        method: 'get',
    });
}

/**
 * 额度审批-补录页面信息
 */
export function sharedApprovalRollbackApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/sharedApprovalRollback`,
        method: 'post',
        params
    });
}

/**
 * 合同签署页面信息
 */
export function sharedContractApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/sharedContract`,
        method: 'post',
        params
    });
}

/**
 * 拍卖协议页面信息
 */
export function sharedPaiMaiApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/salesOrder/sharedPaiMai`,
        method: 'post',
        params
    });
}

/**
 * 根据用户ID和工单编号查询车辆识别码快速估值数据
 */
export function getByUserIdAndDebtNoApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/credit/identity/evalPriceMain/getByUserIdAndDebtNo`,
        method: 'get',
        params
    });
}
/**
 * 查询行政区划
 */
export function regionApi() {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/region`,
        method: 'get',
    });
}

/**
 * 抵押办理
 */
export function mortgageApi(data: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/mortgage`,
        method: 'post',
        data: data
    });
}

/**
 * 查询车管所
 */
export function queryVehicleOfficeApi(data: object) {
    return fetch.request({
        url: `/system_v/api/loan/pay/autoFinance/queryVehicleOffice`,
        method: 'post',
        data
    });
}


/**
 * 提交车务信息（业务员端）
 */
export function addVehicleInfoApi(data: object, params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/addVehicleInfo`,
        method: 'post',
        data,
        params
    });
}

/**
 * 查询车务信息
 */
export function findVehicleInfoApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/findVehicleInfo`,
        method: 'get',
        params
    });
}

/**
 * 查询放款前补充资料列表
 */
export function getLoanSupplementOptionApi(data: object) {
    return fetch.request({
        url: `/system_v/api/loan/audit/loanSupplementData/getLoanSupplementOption`,
        method: 'post',
        data
    });
}

/**
 * 查询工单当前使用的银行卡
 */
export function getDebtRepaymentBankCardApi(params: object) {
    return fetch.request({
        url: '/system_v/api/loan/pay/bankCard/getDebtRepaymentBankCard',
        method: 'get',
        params
    });
}

/*
 * 银行卡识别
*/
export function ocrBankCardFrontApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/identity/ocrBankCardFront',
        method: 'post',
        data
    })
}

/*
 * 更新银行卡图片
*/
export function updateBankCardImageApi(data: object, headers: object) {
    return fetch.request({
        url: '/system_v/api/loan/pay/bankCard/updateBankCardImage',
        method: 'post',
        data,
        headers
    })
}

/*
 * 查询用户车牌号
*/
export function getByUserIdAndDebtNoDriverApi(params: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/driver/getByUserIdAndDebtNo',
        method: 'get',
        params
    })
}

/**
 * 保存审核放款补充资料
 */
export function frontSaveOrUpdateApi(data: object) {
    return fetch.request({
        url: `/system_v/api/loan/audit/loanSupplementData/saveOrUpdate`,
        method: 'post',
        data
    });
}

/**
 * 车辆补充信息，车辆照片查询
 */
export function queryCarImageApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/identity/queryCarImage',
        method: 'post',
        data
    });
}

/**
 * 车辆补充信息，车辆照片提交
 */
export function commitCarImageApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/identity/commitCarImage',
        method: 'post',
        data
    });
}

/**
 * 查询车务资料（根据枚举类型查询）
 */
export function findVehicleDataByOneTypeApi(params: object) {
    return fetch.request({
        url: '/system_v/api/loan/debt/vehicle/findVehicleDataByOneType',
        method: 'get',
        params
    });
}

/**
 * 更新车务资料（根据枚举类型查询）
 */
export function updateVehicleDataByOneTypeApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/debt/vehicle/updateVehicleDataByOneType',
        method: 'post',
        data
    });
}

/**
 * 查看上传保险信息
 */
export function queryDriverCardInsureApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/identity/queryDriverCardInsure',
        method: 'post',
        data
    });
}

/**
 * 上传申请附件
 */
export function uploadApplyLoanAnnexsApi(data: object, headers: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/creditAnnex/uploadApplyLoanAnnexs',
        method: 'post',
        data,
        headers
    });
}

/**
 * 查询放款附件列表
 */
export function queryApplyLoanAnnexsApi(data: object, headers: object) {
    return fetch.request({
        url: '/system_v/api/loan/credit/creditAnnex/queryApplyLoanAnnexs',
        method: 'post',
        data,
        headers
    });
}

/**
 *   获取协议配置
 */

export function getProtocolApi(params: object) {
    return fetch.request({
        url: '/system_v/api/loan/app/getProtocol',
        method: 'get',
        params
    });
}

/**
 * 查询行政区划
 */
export function vehicleRegionApi() {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/detect/region`,
        method: 'get',
    });
}

/**
 * 查询服务网点
 */
export function getServiceCentersApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/detect/getServiceCenters`,
        method: 'get',
        params
    });
}

/**
 * 创建检测订单
 */
export function addOrderApi(data: object) {
    return fetch.request({
        url: '/system_v/api/loan/debt/vehicle/detect/addOrder',
        method: 'post',
        data
    });
}

/**
 * 查询订单信息
查询订单信息
 */
export function orderApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/detect/order`,
        method: 'get',
        params
    });
}

/**
 * 获取租赁明细业务员端-只能查看第一次租赁信息
 */
export function findLoanLeaseByLeaseNoApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/lease/findLoanLeaseByLeaseNo`,
        method: 'post',
        params
    });
}

/**
 * 获取租赁明细基于租赁期数查看
 */
export function findLoanLeaseDetailByLeaseNumberApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/lease/findLoanLeaseDetailByLeaseNumber`,
        method: 'post',
        params
    });
}

/**
 * 获取租赁期数
 */
export function findProductNumberApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/lease/findProductNumber`,
        method: 'post',
        params
    });
}

/**
 * 获取租赁明细业务员端-只能查看第一次租赁信息
 */
export function findLoanLeaseDetailByLeaseNoFirstApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/lease/findLoanLeaseDetailByLeaseNoFirst`,
        method: 'post',
        params
    });
}

/**
 * 判断pdf文件是包含密码，并解密
 */
export function judgePDFAndDecryptApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/credit/creditAnnex/judgePDFAndDecrypt`,
        method: 'post',
        params
    })
}

/**
 * 查询抵押办理信息
 */
export function mortgageInfoApi(params: object) {
    return fetch.request({
        url: `/system_v/api/loan/debt/vehicle/mortgageInfo`,
        method: 'get',
        params
    })
}