import fetch from '../share';
import { splicingObjects } from '@/utils/common';

/**
 * 分享端查询当前业务单处理节点
 */

export function querySalesOrderCurSharedStepApi() {
    return fetch.request({
        url: '/shared_v/api/loan/debt/salesOrder/shared/querySalesOrderCurSharedStep',
        method: 'post',
    });
}

/**
 * 查询认证分组状态
 */

export function loanAllAuthGroupStatusApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/salesOrder/shared/credit/loanAllAuthGroupStatus',
        method: 'post',
        data
    });
}

/**
 * 获取所有认证项状态
*/
export function loanAuthStatusApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAuth/loanAuthStatus',
        method: 'post',
        data: data
    });
}

/**
 * 获取单人认证项状态和授信单状态
 */
export function singleAuthStatusAndCreditStatusApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAuth/singleAuthStatusAndCreditStatus',
        method: 'post',
        data: data
    });
}

/**
 * 身份认证h5页面方式认证获取认证信息
 */
export function getIdentityByOpenIdApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/getIdentityByOpenId',
        method: 'post',
        data: data
    });
}
/**
 * 提交身份认证信息
 */

export function commitApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commit',
        method: 'post',
        data: data
    });
}

/**
 * 查询身份认证信息
 */
export function identityQueryApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/query',
        method: 'post',
        data: data
    });
}

/**
 * 身份认证h5页面方式认证获取页面认证链接地址
 */
export function getIdentityAddressApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/getIdentityAddress',
        method: 'post',
        data: data
    });
}

/**
 * 获取OSS STS临时认证
 */

export function getStsTokenApi(data: any) {
    return fetch.request({
        url: `/shared_v/api/loan/system/sts/getStsToken?code=${data.code}`,
        method: 'get',
    });
}

/**
 * 身份证正面识别
 */
export function ocrIdcardFrontApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/ocrIdcardFront`,
        method: 'post',
        data: data
    })
}

/**
 * 身份证反面识别
 */
export function ocrIdcardBackApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/ocrIdcardBack`,
        method: 'post',
        data: data
    })
}

/**
 * 查询行驶证认证信息

 */
export function queryDriverCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryDriverCard',
        method: 'post',
        data: data
    });
}

/**
 * 行驶证认证--正本
 */
export function ocrDriverCardFrontApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrDriverCardFront',
        method: 'post',
        data: data
    });
}

/**
 * 行驶证认证--副本
 */
export function ocrDriverCardBackApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrDriverCardBack',
        method: 'post',
        data: data
    });
}

/**
 *  保存行驶证认证信息
 */

export function commitDriverCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitDriverCard',
        method: 'post',
        timeout: 30000,
        data: data
    });
}

/**
 * 车300车况定价查询定价结果
 */

export function getVehiclePriceResultApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/vehiclePrice/getVehiclePriceResult',
        method: 'post',
        data: data
    });
}

/**
 * 查询用户所有银行卡
 */
export function getUserBankCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/getUserBankCard',
        method: 'get',
        data
    })
}

/*
 * 银行卡识别
*/
export function ocrBankCardFrontApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrBankCardFront',
        method: 'post',
        data
    })
}

/**
 *   获取协议配置
 */

export function getProtocolApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/app/getProtocol',
        method: 'get',
        params
    });
}

/**
 *   查询银行卡信息（信用认证、个人中心）
 */
export function queryBankCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/bindBankCard/queryBankCard',
        method: 'post',
        data
    })
}

/**
 *   信用认证-预绑定银行卡
 */
export function advanceBindCardForAuthApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/bindBankCard/advanceBindCardForAuth',
        method: 'post',
        data
    })
}

/**
 * 获取图形验证码,绑卡接口使用，防止频繁调用
 */

export function getImgVerificationCodeApi() {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/getImgVerificationCode',
        method: 'post',
    })
}

/*
 * 提交银行卡识别
*/
export function commitOcrBankCardFrontApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitOcrBankCardFront',
        method: 'post',
        data
    })
}
/**
 * 信用认证-绑定银行卡
 */

export function directBindCardForAuthApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/bindBankCard/directBindCardForAuth',
        method: 'post',
        data: data
    });
}

/**
 * 查看配置参数
 */
export function findByGroupAndKeyPublicApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/system/config/findByGroupAndKeyPublic`,
        method: 'get',
        params
    })
}

/*
 * 获取亲属关系分类
*/
export function getRelationTypeApi() {
    return fetch.request({
        url: `/shared_v/api/loan/app/getRelationType`,
        method: 'get',
    })
}

/*
 * 获取亲属关系
*/
export function getRelationAssociationApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/app/getRelationAssociation`,
        method: 'get',
        params
    })
}

/**
 * 学历信息
 */
export function getEducationApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/app/getEducation',
        method: 'get',
        params
    });
}

/**
 * 获取贷款用途
 */
export function getUsageSetApi() {
    return fetch.request({
        url: `/shared_v/api/loan/pay/autoFinance/queryLoanPurpose`,
        method: 'post',
    });
}

/*
 * 获取婚姻信息
*/
export function getMaritalApi() {
    return fetch.request({
        url: '/shared_v/api/loan/app/getMarital',
        method: 'get',
    })
}

/**
 * 省份、市区、县区区域树列表查询
 */

export function getRegionCountyTreeApi() {
    return fetch.request({
        url: '/interface_v/api/loan/app/region/getRegionCountyTree',
        method: 'get',
    });
}

/**
 * 基本信息要素
 */
export function checkBaseInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/base/checkBaseInfo`,
        method: 'post',
        data
    });
}

/**
 * 保存基本信息
 */
export function saveBaseInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/base/saveBaseInfo`,
        method: 'post',
        data
    });
}

/**
 * 查询基本信息
 */
export function queryBaseInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/base/queryBaseInfo`,
        method: 'post',
        data
    });
}

/*
 * 获取所属职业
*/
export function getOccupationApi() {
    return fetch.request({
        url: `/shared_v/api/loan/app/getOccupation`,
        method: 'get',
    })
}

/**
 *  保存工作信息
 */
export function saveWorkInfoApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/work/saveWorkInfo',
        method: 'post',
        data
    });
}

/**
 *  查询工作信息
 */
export function queryWorkInfoApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/work/queryWorkInfo',
        method: 'post',
        data
    });
}

/**
 * 验证借款用途是否合法
 */
export function validateLoanUseApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/pay/autoFinance/validateLoanUse`,
        method: 'post',
        data,
    })
}

/**
 * 根据金额获取必填项
 */
export function findRequiredByAmount(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAnnex/findRequiredByAmount`,
        method: 'get',
        params
    })
}

/**
 * 根据必填项获取最大额度
 */
export function findRequiredByMaxAmount(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAnnex/findRequiredByMaxAmount?${splicingObjects(params)}`,
        method: 'get',
    })
}

/**
 * 提交预审金额
 */
export function commitDebtUserAmountApi(params: object, headers: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAmount/commitDebtUserAmount`,
        method: 'post',
        params,
        headers
    });
}

/**
 * 查询进件结果
 */

export function queryCreateOrderResultApi() {
    return fetch.request({
        url: '/shared_v/api/loan/pay/autoFinance/queryCreateOrderResult',
        method: 'post',
    });
}

/*
 * 录入客户信息
*/
export function findUserApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/user/findUser',
        method: 'get',
        params
    })
}

/**
* 授权签约列表
*/
export function authSignContractApi() {
    return fetch.request({
        url: `/shared_v/api/loan/debt/contract/authSignContract`,
        method: 'get',
    })
}

/**
 * 授权签约(后置等待)
 */
export function authSignContractFollowApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/debt/contract/authSignContractFollow`,
        method: 'get',
        params
    })
}

/**
 * 资方授权回调
 */

export function capitalAuthCallbackApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/contract/capitalAuthCallback',
        method: 'get',
        params
    });
}

/**
 * 资方签约回调
 */

export function capitalSignCallbackApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/contract/capitalSignCallback',
        method: 'get',
        params
    });
}

/**
 * batchPreAudit
 */
export function batchPreAuditApi() {
    return fetch.request({
        url: `/shared_v/api/loan/pay/autoFinance/batch/batchPreAudit`,
        method: 'post',
    })
}

/**
 * 获取短信验证码
 */
export function acquireSmsCodeApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/pay/autoFinance/acquireSmsCode`,
        method: 'post',
        params
    })
}

/**
 * 验证短信验证码
 */
export function verifySmsCodeApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/pay/autoFinance/verifySmsCode`,
        method: 'post',
        params
    })
}

/**
 * 根据shateToken获取客户端登录token
 */
export function loginCustomerApi() {
    return fetch.request({
        url: `/shared_v/api/loan/user/loginCustomer`,
        method: 'post',
    })
}

/*
 * 分享补录
*/
export function getAllNoCreditAuthApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAuth/getAllNoCreditAuth',
        method: 'post',
        data
    })
}

/**
 *
获取漆面、内饰、工程
 */
export function getPaintworkAndInteriorAndEngineeringApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/app/getPaintworkAndInteriorAndEngineering`,
        method: 'get',
        params
    })
}

/*
 * 查询用户车牌号
*/
export function getSharedByUserIdAndDebtNoApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/driver/getSharedByUserIdAndDebtNo',
        method: 'get',
        params
    })
}
/**
 *   车辆登记识别认证
 */
export function ocrDriverCardRegistrationApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrDriverCardRegistration',
        method: 'post',
        data,
        timeout: 40000,
    })
}

/**
 * 保险信息上传
 */
export function updateDriverCardInsureApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/updateDriverCardInsure',
        method: 'post',
        data
    });
}

/**
 * 获取保险信息上传选择必填项
 */
export function updateDriverCardInsureChooseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/updateDriverCardInsureChoose',
        method: 'post',
        data
    });
}

/**
 * 车辆补充信息，车辆照片查询
 */
export function queryCarImageApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryCarImage',
        method: 'post',
        data
    });
}

/**
 * 驾驶证认证--正本
 */
export function ocrDriverLicenseFrontApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrDriverLicenseFront',
        method: 'post',
        data
    });
}

/**
 * 驾驶证认证--副本
 */
export function ocrDriverLicenseBackApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrDriverLicenseBack',
        method: 'post',
        data
    });
}

/**
 * 查看车辆登记证书信息
 */
export function queryDriverCardRegistrationApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryDriverCardRegistration',
        method: 'post',
        data
    });
}

/**
 * 查询驾驶证信息
 */
export function queryDriverLicenseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryDriverLicense',
        method: 'post',
        data
    });
}

/**
 * 获取指定用户指定的认证项-前端使用
 */
export function userAllAuthByWayCodesToFrontApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAuth/userAllAuthByWayCodesToFront`,
        method: 'post',
        data: data
    })
}

/**
 * 查询保险信息
 */
export function queryDriverCardInsureApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryDriverCardInsure',
        method: 'post',
        data
    });
}

/**
 * 保存行驶证特定信息 车辆状态
 */
export function saveOrUpdateSpecificApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/driver/saveOrUpdateSpecific`,
        method: 'post',
        data: data
    })
}

/**
 *   提交车辆登记提交车辆登记识别认证认证
 */
export function commitDriverCardRegistrationApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitDriverCardRegistration',
        method: 'post',
        data
    })
}

/**
 * 保存驾驶证认证信息
 */
export function commitDriverLicenseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitDriverLicense',
        method: 'post',
        data
    });
}

/**
 * 保险信息上传提交
 */
export function commitDriverCardInsureApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitDriverCardInsure',
        method: 'post',
        data
    });
}

/**
 * 车辆补充信息，车辆照片提交
 */
export function commitCarImageApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitCarImage',
        method: 'post',
        data
    });
}

/**
 * 提交车辆补充信息认证项
 */
export function commitCarSupplementInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/commitCarSupplementInfo`,
        method: 'post',
        data: data
    })
}

/**
 * 查询材料证明上传列表
 */
export function materialAnnexTypesApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAnnex/materialAnnexTypes',
        method: 'get',
        params
    });
}

/**
 * 查询材料证明信息认证项保存
 */
export function completeMaterialApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAnnex/completeMaterial',
        method: 'post',
        params
    });
}


/**
 * 查询材料证明信息附件
 */
export function querymaterialAnnexApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAnnex/querymaterialAnnex?${splicingObjects(params)}`,
        method: 'post',
    });
}

/**
 * 上传材料证明信息附件
 */
export function uploadmaterialAnnexApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/creditAnnex/uploadmaterialAnnex',
        method: 'post',
        data
    });
}

/**
 * 判断pdf文件是包含密码，并解密
 */
export function judgePDFAndDecryptApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/creditAnnex/judgePDFAndDecrypt`,
        method: 'post',
        params
    })
}

/**
 * 查询营业执照信息
 */
export function queryBusinessLicenseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/queryBusinessLicense',
        method: 'post',
        data
    });
}


/**
 * 识别营业执照信息
 */
export function ocrBusinessLicenseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/ocrBusinessLicense',
        method: 'post',
        data
    });
}

/**
 * 保存营业执照
 */
export function commitBusinessLicenseApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/credit/identity/commitBusinessLicense',
        method: 'post',
        data
    });
}

/**
 * 查询配偶证件信息
 */
export function querySpouseInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/querySpouseInfo`,
        method: 'post',
        data: data
    })
}

/**
 * 保存配偶证件信息
 */
export function saveUserSpouseInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/saveUserSpouseInfo`,
        method: 'post',
        data: data
    })
}

/**
 * 保存借款人配偶工作信息表
 */
export function saveOrUpdateSpouseWorkInfoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/work/saveOrUpdateSpouseWorkInfo`,
        method: 'post',
        data: data
    })
}

/**
 * 根据授信单号查询借款人配偶工作信息表
 */
export function getSpouseWorkInfoByCreditNoApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/work/getSpouseWorkInfoByCreditNo`,
        method: 'post',
        data: data
    })
}

/**
 * 查询借款人住房信息
 */
export function queryUserHouseApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/queryUserHouse`,
        method: 'post',
        data: data
    })
}

/**
 * 保存借款人住房信息
 */
export function saveUserHouseApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/saveUserHouse`,
        method: 'post',
        data: data
    })
}

/**
 * 提交基本信息合并页认证项
 */
export function commitAdditionalBaseMergeApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/commitAdditionalBaseMerge`,
        method: 'post',
        data: data
    })
}

/**
 * 分享端合同签署页面信息
 */
export function contractPageDataApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/debt/salesOrder/shared/contractPageData`,
        method: 'post',
        params
    });
}

/*
 * 查询合同签署状态
*/
export function getContractStatusApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/contract/getContractStatus',
        method: 'get',
        params
    })
}

/**
 * 车贷确认拿钱
 */
export function confirmSignApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/debt/salesOrder/shared/confirmSign`,
        method: 'post',
        params
    });
}

/**
 *  预览合同
 */
export function preContractApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/contract/preContract',
        method: 'get',
        params
    });
}


/**
 * 合同生成或跳转签章页面(type的值为'sign_html'时跳转H5签章页面，其他情况只返回合同链接)
 */
export function createContractApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/debt/contract/createContract`,
        method: 'GET',
        timeout: 60000,
        params
    });
}

/**
 * 拍司令签署合同
 */
export function signContractPslApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/contract/signContractPsl',
        method: 'get',
        params
    });
}


/**
 * 获取拍司令二维码地址
 */

export function getPslContractUrlApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/pslContract/getPslContractUrl',
        method: 'get',
        params
    });
}

/**
 * 提交拍司令数据
 */
export function pslCommitApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/loan/debt/pslContract/pslCommit',
        method: 'get',
        params
    });
}

/**
 * 查询预审阶段收入证明信息
 * @param {*} data
 * @returns
 */
export function queryProofOfIncomeApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/queryProofOfIncome`,
        method: 'post',
        data
    })
}

/**
 * 提交预审阶段收入证明信息认证项
 * @param {*} data
 * @returns
 */
export function commitProofOfIncomeApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/commitProofOfIncome`,
        method: 'post',
        data
    })
}


/**
 * 根据用户ID和工单编号查询车辆识别码快速估值数据
 */
export function getByUserIdAndDebtNoApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/credit/identity/evalPriceMain/getByUserIdAndDebtNo`,
        method: 'get',
        params
    });
}


// 月月租

/**
 * 行驶证关联工单授信单信息控制器
 */
export function findLoanCarInfoApi() {
    return fetch.request({
        url: `/shared_v/api/loan/credit/driverRelation/findLoanCarInfo`,
        method: 'get',
    });
}

/**
 * 查询工单还款银行卡
 */
export function getDebtRepaymentBankCardApi() {
    return fetch.request({
        url: `/shared_v/api/loan/pay/bankCard/shared/getDebtRepaymentBankCard`,
        method: 'get',
    });
}


/**
 * 是否可以更改卡
 */
export function canChangeCardApi() {
    return fetch.request({
        url: `/shared_v/api/loan/pay/bankCard/canChangeCard`,
        method: 'post',
    });
}


/**
 * 绑卡协议
 */
export function bindCardProtocolApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/bindCardProtocol',
        method: 'post',
        data
    });
}

/**
 * 更改代扣卡
 */
export function changeWithholdCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/changeWithholdCard',
        method: 'post',
        data
    });
}

/**
 * 绑卡确认
 */
export function bindCardConfirmApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/bindCardConfirm',
        method: 'post',
        data
    });
}

/**
 * 合同生成或跳转签章页面(type的值为'sign_html'时跳转H5签章页面，其他情况只返回合同链接)
 * LEASE_AUTH_GROUP("LEASE_AUTH_GROUP","月月租-签约授权合同组","",40001),
 * LEASE_BUY_SALE_GROUP("LEASE_BUY_SALE_GROUP","月月租-汽车买卖合同组","",40002),
 * LEASE_CAR_BUY_GROUP("LEASE_CAR_BUY_GROUP","月月租-租赁回购合同组","",40003),
 * LEASE_RELET_BUY_GROUP("LEASE_RELET_BUY_GROUP","月月租-续租回购合同组","",40004),
 */
export function createLeaseContractApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/lease/debt/contract/createLeaseContract`,
        method: 'GET',
        timeout: 60000,
        params
    });
}


/**
 *  月月租赁预览合同
 */
export function preLeaseContractApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/lease/debt/contract/preLeaseContract',
        method: 'get',
        params
    });
}

/**
 *  授权签约（租赁业务）
 */
export function createLeaseContractByBebtNoApi(params: object) {
    return fetch.request({
        url: '/shared_v/api/lease/debt/contract/createLeaseContractByBebtNo',
        method: 'get',
        params
    });
}

/**
 * 合同签署成功生成详情和还款计划
 */
export function insertLoanLeaseDetailAndPlanApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/lease/insertLoanLeaseDetailAndPlan`,
        method: 'post',
        params
    });
}


/**
 * 合同签署成功生成详情和还款计划
 */
export function findLoanLeaseByLeaseNoApi() {
    return fetch.request({
        url: `/shared_v/api/loan/lease/findLoanLeaseByLeaseNo`,
        method: 'post',
    });
}


/**
 * 获取租赁明细基于租赁期数查看
 */
export function findLoanLeaseDetailByLeaseNumberApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/lease/findLoanLeaseDetailByLeaseNumber`,
        method: 'post',
        params
    });
}


/**
 * 获取租赁期数
 */
export function findProductNumberApi(params: object) {
    return fetch.request({
        url: `/shared_v/api/loan/lease/findProductNumber`,
        method: 'post',
        params
    });
}

/**
 * 支付失败查询支付金额接口
 */
export function findLoanLeasePayApplyApi() {
    return fetch.request({
        url: `/shared_v/api/loan/lease/findLoanLeasePayApply`,
        method: 'post',
    });
}

/**
 * 是否已绑卡
 */
export function isAlreadyBindApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/isAlreadyBind',
        method: 'post',
        data
    });
}

/**
 * 查询抵押费用支付信息
 */
export function otherPaymentApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/newPayment/otherPayment',
        method: 'post',
        data
    });
}

/**
 * 绑卡
 */
export function bindCardApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/bankCard/bindCard',
        method: 'post',
        data
    });
}

/**
 *   还款确认
 */
export function paymentConfirmApi(data: object) {
    return fetch.request({
        url: '/shared_v/api/loan/pay/newPayment/paymentConfirm',
        method: 'post',
        data
    })
}

/**
 * 查询还款或其他支付结果
 */
export function queryPaymentResultApi(params: object, headers: object) {
    return fetch.request({
        url: `/shared_v/api/loan/pay/newPayment/queryPaymentResult`,
        method: 'post',
        params,
        headers
    });
}

/**
 *
保存授信单埋点数据
 */
export function saveOrUpdateApi(data: object) {
    return fetch.request({
        url: `/shared_v/api/loan/audit/buriedPoint/saveOrUpdate`,
        method: 'post',
        data
    })
}

/**
 *  获取租赁还款方式
 */
export function findProductModeApi() {
    return fetch.request({
        url: '/shared_v/api/loan/lease/findProductMode',
        method: 'post',
    });
}
