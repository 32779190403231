export default [
    {
        path: "/businessHome",
        name: "businessHome",
        component: () => import("@/views/business/home/index.vue"),
        meta: { title: '车来呗', returnBtn: false, module: 'business' }
    },
    {
        path: '/businessSetup',
        component: () => import('@/views/business/setup/index.vue'),
        name: 'businessSetup',
        meta: { title: '设置', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessSetCode',
        component: () => import('@/views/business/setup/setCode.vue'),
        name: 'businessSetCode',
        meta: { title: '修改密码', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessUser',
        component: () => import("@/views/business/userList/index.vue"),
        name: 'businessUser',
        meta: { title: '业务单列表', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessInformation',
        component: () => import('@/views/business/userList/Information.vue'),
        name: 'businessInformation',
        meta: { title: '客户信息', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessSupplement',
        component: () => import('@/views/business/userList/supplement.vue'),
        name: 'businessSupplement',
        meta: { title: '信息补录', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessList',
        component: () => import("@/views/business/vehicleList/index.vue"),
        name: 'businessList',
        meta: { title: '车务单列表', returnBtn: true, module: 'business' }
    },

    {
        path: '/businessDetail',
        component: () => import('@/views/business/vehicleList/detail.vue'),
        name: 'businessDetail',
        meta: { title: '车务信息', returnBtn: true, module: 'business' }
    },
    {
        path: '/uploadCredentials',
        component: () => import('@/views/business/vehicleList/uploadCredentials.vue'),
        name: 'uploadCredentials',
        meta: { title: '补充材料', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessEntry',
        component: () => import('@/views/business/entry/index.vue'),
        name: 'businessEntry',
        meta: { title: '信息录入', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessPromotion',
        component: () => import('@/views/business/promotion/index.vue'),
        name: 'businessPromotion',
        meta: { title: '我的邀请码', returnBtn: true, module: 'business' }
    },
    {
        path: '/assistInHandling',
        component: () => import('@/views/assistInHandling/index.vue'),
        name: 'assistInHandling',
        meta: { title: '信息审核', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessContract',
        component: () => import('@/views/business/userList/contract.vue'),
        name: 'businessContract',
        meta: { title: '合同签署', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessBreach',
        component: () => import('@/views/business/userList/breach.vue'),
        name: 'businessBreach',
        meta: { title: '三方协议', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessMortgage',
        component: () => import('@/views/business/userList/mortgage.vue'),
        name: 'businessMortgage',
        meta: { title: '办理抵押', returnBtn: true, module: 'business' }
    },
    {
        path: '/businessAdd',
        component: () => import('@/views/business/vehicleList/add.vue'),
        name: 'businessAdd',
        meta: { title: '车务信息', returnBtn: true, module: 'business' }
    },
    {
        path: '/loanSupplement',
        component: () => import('@/views/business/loanSupplement/index.vue'),
        name: 'loanSupplement',
        meta: { title: '补充材料', returnBtn: true, module: 'business' }
    },
    {
        path: '/loanSupplementUpload',
        component: () => import('@/views/business/loanSupplement/upload.vue'),
        name: 'loanSupplementUpload',
        meta: { title: '补充材料', returnBtn: true, module: 'business' }
    },
    {
        path: '/monthlyRent',
        component: () => import('@/views/business/monthlyRent/index.vue'),
        name: 'monthlyRent',
        meta: { title: '月月租', returnBtn: true, module: 'business' }
    },
    {
        path: '/monthlyRentDetail',
        component: () => import('@/views/business/monthlyRent/detail.vue'),
        name: 'monthlyRentDetail',
        meta: { title: '客户信息', returnBtn: true, module: 'business' }
    },
    {
        path: '/vehicleInspection',
        component: () => import('@/views/business/monthlyRent/vehicleInspection.vue'),
        name: 'vehicleInspection',
        meta: { title: '线下验车', returnBtn: true, module: 'business' }
    },
    {
        path: '/vehicleInspectionUpload',
        component: () => import('@/views/business/monthlyRent/vehicleInspectionUpload.vue'),
        name: 'vehicleInspectionUpload',
        meta: { title: '线下验车', returnBtn: true, module: 'business' }
    },
    {
        path: '/vehicleProtocol',
        component: () => import('@/views/business/monthlyRent/protocol.vue'),
        name: 'vehicleProtocol',
        meta: { title: '线下验车', returnBtn: true, module: 'business' }
    },
    {
        path: '/vehicleTransfer',
        component: () => import('@/views/business/monthlyRent/vehicleTransfer.vue'),
        name: 'vehicleTransfer',
        meta: { title: '车辆过户', returnBtn: true, module: 'business' }
    },
    {
        path: '/leaseContractDetail',
        component: () => import('@/views/business/monthlyRent/leaseContractDetail.vue'),
        name: 'leaseContractDetail',
        meta: { title: '查看详情', returnBtn: true, module: 'business' }
    },
]