import { defineStore } from "pinia";
export const useOrderNumberStore = defineStore('orderNumber', () => {
  const debtNo = ref(''); // 借款单工单编号
  const userId = ref(''); // 用户id
  const salesOrderNo = ref(''); // 业务单号
  const creditNo = ref(''); // 
  const setDebtNo = data => {
    debtNo.value = data;
  };
  const setUserId = data => {
    userId.value = data;
  };
  const setSalesOrderNo = data => {
    salesOrderNo.value = data;
  };
  const setCreditNo = data => {
    creditNo.value = data;
  };
  return {
    debtNo,
    setDebtNo,
    userId,
    setUserId,
    salesOrderNo,
    setSalesOrderNo,
    creditNo,
    setCreditNo
  };
}, {
  persist: true
});