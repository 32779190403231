import { showLoadingToast } from 'vant';

export function isMobile() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true // 手机端
    } else {
        return false // alert('PC端')
    }
}


/**
 * 验证校验
 */
export function smsCodeInput(data: any) {
    return data = data.replace(/[^\d]/g, '');
}

/**
 * 设置输入框只能输入数字字母
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function alphanumericFu(data: any) {
    // 移除非数字和字母以外的字符
    return data.replace(/[^0-9A-Za-z]/g, '');
}
/**
 * 设置密码框
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function passwordFu(data: any) {
    return data.replace(/[\u4E00-\u9FA5]/g, '')
}
/**
 * 设置只能输入手机号
 */
export function phoneFu(data: any) {
    if (data[0] != 1) {
        return ''
    }
    if (data.length == 1) {
        return data = data.replace(/[^1]/g, '');
    } else {
        return data = data.replace(/\D/g, '');
    }
}

/**
 * 设置全局loading动画
 */

// 全局加载动画

export const loadingFu = (text?: string) => {
    const loading = showLoadingToast({
        message: text || '加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
        className: 'z_index'
    });
    return loading
}

/**
 * 日期格式转换
*/

export const dateStrToDateFormat = (str: string, format = 'yyyy-mm-dd') => {
    if (!str) return ''

    const date = new Date(str)
    const year = date.getFullYear()
    let month = (date.getMonth() + 1) + ''
    let day = date.getDate() + ''
    let hours = date.getHours() + ''
    let minutes = date.getMinutes() + ''
    let seconds = date.getSeconds() + ''
    month = month.padStart(2, '0')
    day = day.padStart(2, '0')
    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')
    seconds = seconds.padStart(2, '0')

    if (format == 'yyyy-mm-dd') {
        return `${year}-${month}-${day}`
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
}

export function getDatetime() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    const day = now.getDate()
    const LastWeekDate = new Date(now.getTime() - 7 * 24 * 3600 * 1000)
    const lastYear = LastWeekDate.getFullYear()
    const lastMonth = LastWeekDate.getMonth() + 1
    const LastDay = LastWeekDate.getDate()
    //现在的时间
    let clock = `${year}年`
    if (month < 10) clock += "0"
    clock += `${month}月`
    if (day < 10) clock += "0"
    clock += `${day}日`
    //一周前的时间
    let lastClock = `${lastYear}年`
    if (lastMonth < 10) lastClock += "0"
    lastClock += `${lastMonth}月`
    if (LastDay < 10) lastClock += "0"
    lastClock += `${LastDay}日`
    // 现在的时间
    let clockShow = `${year},`
    if (month < 10) clockShow += "0"
    clockShow += `${month},`
    if (day < 10) clockShow += "0"
    clockShow += `${day}`
    //一周前的时间
    let lastClockShow = `${lastYear},`
    if (lastMonth < 10) lastClockShow += "0"
    lastClockShow += `${lastMonth},`
    if (LastDay < 10) lastClockShow += "0"
    lastClockShow += `${LastDay}`
    return { clock, clockShow, lastClock, lastClockShow }
}

// 获取当前时间跟30天前的事件
export function getCurrentDate() {
    // 获取当前日期
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1
    const currentDay = currentDate.getDate()
    // 设置日期为30天前
    currentDate.setDate(currentDate.getDate() - 30);
    // 获取30天前的年、月、日
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 注意月份从0开始，所以要加1
    const day = currentDate.getDate();
    const clock = `${currentYear}年${currentMonth < 10 ? '0' + currentMonth : currentMonth}月${currentDay < 10 ? '0' + currentDay : currentDay}日`
    const clockShow = `${currentYear},${currentMonth < 10 ? '0' + currentMonth : currentMonth},${currentDay < 10 ? '0' + currentDay : currentDay}`
    const lastClock = `${year}年${month < 10 ? '0' + month : month}月${day < 10 ? '0' + day : day}日`
    const lastClockShow = `${year},${month < 10 ? '0' + month : month},${day < 10 ? '0' + day : day}`
    return { clock, clockShow, lastClock, lastClockShow }
}


// JS实现日期比较大小(JavaScript)
export function compareDate(date1: string | number | Date, date2: string | number | Date) {
    const oDate1 = new Date(date1);
    const oDate2 = new Date(date2);
    if (oDate1.getTime() > oDate2.getTime()) {
        // console.log('第一个大');
        return true;
    } else if (oDate1.getTime() < oDate2.getTime()) {
        // console.log('第二个大');
        return false;
    } else if (oDate1.getTime() == oDate2.getTime()) {
        // console.log('一样大');
        return true;
    }
}

/**
 * area数据格式转换
*/
export function areasMap(arr: any[]) {
    arr.forEach(item => {
        item.text = item.name
        item.value = item.code
        if (item.children && item.children.length) {
            areasMap(item.children)
        }
    })
    return arr
}
