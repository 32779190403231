import _defineProperty from "/usr/share/we-saas/h5-car-operator/deploy/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { defineStore } from "pinia";
class infoUseDefalut {
  constructor() {
    _defineProperty(this, "icon", '');
    _defineProperty(this, "id", '');
    _defineProperty(this, "inviteCode", "");
    _defineProperty(this, "name", "");
    _defineProperty(this, "orgName", "");
    _defineProperty(this, "orgCode", "");
  }
}
export const useUserStore = defineStore('use', () => {
  const token = ref('');
  const isUseYyz = ref(false);
  const infoUse = ref(new infoUseDefalut());
  const orderType = ref('');
  const notLoggedIn = ref(false);
  const setTokenFu = data => {
    token.value = data;
  };
  const setIsUseYyz = data => {
    isUseYyz.value = data;
  };
  const setInfoUse = data => {
    infoUse.value = data;
  };
  const setOrderType = data => {
    orderType.value = data;
  };
  const setNotLoggedIn = data => {
    notLoggedIn.value = data;
  };
  /**
   * 重置数据
   */
  const resetState = () => {
    token.value = '';
    isUseYyz.value = false;
    infoUse.value = new infoUseDefalut();
    orderType.value = '';
    notLoggedIn.value = false;
  };
  return {
    token,
    isUseYyz,
    infoUse,
    orderType,
    notLoggedIn,
    setTokenFu,
    setIsUseYyz,
    setInfoUse,
    setOrderType,
    setNotLoggedIn,
    resetState
  };
}, {
  persist: true
});