import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import business from "./business";
import share from "./share"
import monthlyRent from './monthlyRent'
import { useUserStore } from "@/store/modules/user";
import { useShareStore } from "@/store/modules/share"
import { saveOrUpdate } from "@/minis";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: { title: '登陆', returnBtn: false, module: 'business' }
    },
    {
        path: '/apply',
        component: () => import("@/views/apply/index.vue"),
        name: 'apply',
        meta: { title: '车来呗', returnBtn: false, keepAlive: true }
    },
    {
        path: "/protocolAll",
        name: "protocolAll",
        component: () => import("@/views/protocolAll/index.vue"),
        meta: { title: '协议', returnBtn: true, module: 'loanAuth', noAuthority: true }
    },
    {
        path: "/readProtocol",
        name: "readProtocol",
        component: () => import("@/views/protocolAll/readProtocol.vue"),
        meta: { title: '协议', returnBtn: true, module: 'loanAuth', noAuthority: true }
    },
    {
        path: '/interactive',
        name: 'interactive',
        component: () => import("@/views/interactive/index.vue"),
        meta: { title: '', returnBtn: true, noAuthority: true }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: [...routes, ...business, ...share, ...monthlyRent]
});

/**
 * 路由拦截
 *
 */

router.beforeEach((to, from, next) => {
    const useUser = useUserStore()
    const token = useUser.token
    const moduleName = to.meta.module
    const noAuthority = to.meta?.noAuthority
    if (noAuthority) {
        next()
        return
    }
    // 判断为业务模块的
    if (moduleName == 'business') {
        if (token) {
            if (to.matched.length === 0) {
                next('/businessHome')
            } else if (to.path == '/') {
                next('/businessHome')
            } else {
                next()
            }
        } else {
            if (to.path == '/') {
                next()
            } else {
                next('/')
            }
        }
    } else if (moduleName == 'share') {
        const useShare = useShareStore()
        const sharedToken = useShare.shareToken
        // store.dispatch('app/saveOrUpdate')
        // const sharedToken = getAssistToken()
        if (to.matched.length === 0) { // 如果未匹配到路由
            next('/noAuthority')
        } else {
            if (!sharedToken) {
                next(`/noAuthority`)
            } else {
                saveOrUpdate()
                next()
            }
        }
    } else {
        //请求不存在的路由跳转到登录
        const matchedArr = to.matched
        if (matchedArr.length > 0) {
            next();
        } else {
            next('/')
        }
    }
})

export default router;
