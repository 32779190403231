import "core-js/modules/es.array.push.js";
import { createSalesOrderBySourceApi, vehicleDetailApi, salesOrderDetailApi } from "@/http/api/system";
import { useOrderNumberStore } from "@/store/modules/orderNumber";
import { useUserStore } from "@/store/modules/user";
import { showToast, closeToast } from 'vant';
import { loadingFu } from "@/utils/utils";
import router from "@/router";
import { saveOrUpdateApi } from "@/http/api/share";
export function salesOrderDetailFu() {
  loadingFu();
  const useOrderNumber = useOrderNumberStore();
  const params = {
    salesOrderNo: useOrderNumber.salesOrderNo
  };
  salesOrderDetailApi(params).then(res => {
    closeToast();
    const {
      code,
      data,
      mesg
    } = res;
    if (code == 'SM000000') {
      const {
        curStepButton
      } = data;
      data.code = curStepButton.buttonCode;
      assistFu({
        item: data,
        jumpMethod: true
      });
    } else {
      showToast(mesg);
    }
  }).catch(req => {
    closeToast();
    showToast(req.mesg);
  });
}
export function assistFu(assistParams) {
  const useOrderNumber = useOrderNumberStore();
  const {
    item,
    callBack,
    jumpMethod
  } = assistParams;
  useOrderNumber.setDebtNo(item.debtNo);
  item.loanUserId && useOrderNumber.setUserId(item.loanUserId);
  useOrderNumber.setSalesOrderNo(item.salesOrderNo);
  useOrderNumber.setCreditNo(item.creditNo);
  let path;
  let pathQuery = {};
  console.log(item, 'itemitemitemitem');
  switch (item.code) {
    case 'PRODUCT_OP':
      // this.productShow = true
      // this.productParams.vestCode = item.vestCode
      // this.productParams.appCode = item.appCode
      // this.productParams.salesOrderNo = item.salesOrderNo
      // this.productParams.productType = '2'
      break;
    case 'YYZ_PRODUCT_OP':
      // 月月租选择产品进件
      // this.productShow = true
      // this.productParams.vestCode = item.vestCode
      // this.productParams.appCode = item.appCode
      // this.productParams.salesOrderNo = item.salesOrderNo
      // this.productParams.productType = '3'
      break;
    case 'ZLTJ_OP':
    case 'ZLTJ_VIEW':
      path = '/assistInHandling';
      pathQuery = {
        ...pathQuery,
        ...{
          view: true
        }
      };
      // this.querySalesOrderCurSharedStepFu()
      // path = '/businessPre'
      break;
    case 'EDYS_OP': // 额度预审
    case 'EDYS_VIEW':
      if (item.orderType == 3) {
        // ordertype为月月租产品
        path = '/assistInHandling';
      } else {
        path = '/businessPre';
      }
      break;
    case 'EDSP_VIEW':
    case 'EDSP_OP':
    case 'JZPGBL_OP':
      // 额度审批
      path = '/businessQuota';
      break;
    case 'XXBL_OP':
      // 认证项信息补录界面
      path = '/businessSupplement';
      break;
    case 'HTQS_OP': // 签署合同
    case 'QRNQ_OP':
    case 'HTQS_VIEW':
      path = '/businessContract';
      break;
    case 'PMXY_OP': // 三方协议签署
    case 'PMXY_VIEW':
      path = '/businessBreach';
      break;
    case 'DYBL_OP': // DYBL_OP  办理抵押
    case 'DYBL_VIEW':
    case 'CWXX_OP':
      path = 'DYBL';
      vehicleDetailFu(path);
      break;
    case 'DYZLTJ_OP':
      path = '/businessAdd';
      break;
    case 'FKZLBC_OP':
      // 放款申请补充材料
      path = '/loanSupplement';
      break;
    case 'FKSQ_OP':
      // 放款申请
      // this.showApplication(item)
      break;
    case 'CONTACT_OP':
    case 'YYZ_CONTACT_OP':
      // 联系用户
      window.location.href = `tel:${item.loanUserPhone}`;
      break;
    case 'DETAIL_OP': // 查看详情
    case 'YYZ_DETAIL_OP':
      console.log('item.orderType', item.orderType);
      if (item.orderType == 2) {
        router.push('/businessInformation');
      }
      if (item.orderType == 3) {
        // this.$router.push("/rentalDetail");
        router.push('/monthlyRentDetail');
      }
      break;
    case 'INTERRUPT_OP': // 终止按钮
    case 'YYZ_INTERRUPT_OP':
      // 月月租终止业务单
      callBack(item);
      break;
    case 'YYZ_CREATE_OP':
      // 创建月月租订单按钮
      createSalesOrderBySourceFu(item.salesOrderNo);
      break;
    case 'YYZ_SHARED_OP':
      //  处理月月租协助办理
      path = '/assistInHandling';
      break;
    case 'YYZ_ZL_OP':
      // 月月租租赁单流程
      // path = '/assistInHandling'
      break;
    case 'YYZ_ZLTJ_OP':
      // 处理月月租资料提交（认证）
      // path = '/assistInHandling'
      break;
    case 'YYZ_XXYC_OP':
      //  处理月月租线下验车
      if (item.channelCode == 'xl') {
        path = '/vehicleInspectionUpload';
      }
      if (item.channelCode == 'cbs') {
        path = '/vehicleInspection';
      }
      break;
    case 'YYZ_XXYC_VIEW':
      //  查看月月租线下验车
      if (item.channelCode == 'xl') {
        path = '/vehicleInspectionUpload';
      }
      if (item.channelCode == 'cbs') {
        path = '/vehicleInspection';
      }
      pathQuery = {
        ...pathQuery,
        ...{
          view: true
        }
      };
      break;
    case 'YYZ_HTQS_OP': //  租赁合同
    case 'YYZ_HTQS_VIEW':
      path = '/leaseContractDetail';
      pathQuery = {
        ...pathQuery,
        ...{
          relationNo: item.relationNo
        }
      };
      break;
    case 'YYZ_GHBL_OP': //  处理月月租过户办理
    case 'YYZ_GHBL_VIEW':
      //  处理月月租过户办理
      path = '/vehicleTransfer';
      break;
    case 'YYZ_CWXX_OP':
      //  查看车务
      // path = '/assistInHandling'
      break;
    case 'YYZ_FKSQ_OP':
      //  处理月月租申请放款
      // path = '/assistInHandling'
      break;
    default:
      break;
  }
  if (path) {
    console.log(path, 'poath');
    if (path == 'DYBL') {
      return;
    }
    const address = {
      path,
      query: pathQuery
    };
    if (jumpMethod) {
      router.replace(address);
    } else {
      router.push(address);
    }
  }
}
/**
* 创建月月租订单
*/
export const createSalesOrderBySourceFu = params => {
  loadingFu();
  const defaultParams = {
    orderType: 3,
    productCode: '',
    sourceSalesOrderNo: params,
    termTypeCode: ''
  };
  createSalesOrderBySourceApi(defaultParams).then(res => {
    const {
      code
    } = res;
    closeToast();
    if (code == 'SM000000') {
      router.replace('/monthlyRent');
    } else {
      showToast(res.mesg);
    }
  }).catch(req => {
    closeToast();
    showToast(req.mesg);
  });
};
/**
 * 查看抵押办理最新状态
 */
export const vehicleDetailFu = (path = '') => {
  const useOrderNumber = useOrderNumberStore();
  const useUser = useUserStore();
  const params = {
    agentId: useUser.infoUse.id,
    debtNo: useOrderNumber.debtNo
  };
  loadingFu();
  vehicleDetailApi(params).then(res => {
    console.log(res, 'res');
    const {
      data,
      code,
      mesg
    } = res;
    closeToast();
    if (code == 'SM000000') {
      if (data) {
        router.replace({
          path: 'businessList',
          query: {
            phone: res.data.phone
          }
        });
      } else {
        if (path) {
          router.push(path);
        }
        router.replace('/businessMortgage');
      }
    } else {
      closeToast();
      showToast(mesg);
    }
  }, req => {
    closeToast();
    showToast(req.mesg);
  });
};
export const saveOrUpdate = () => {
  const detailData = getOperatingDetail();
  saveOrUpdateApi(detailData);
};
/**
 * 获取设备信息
 */
export const getOperatingDetail = () => {
  const route = useRoute();
  return {
    osPlatformNo: getOsPlatformNo(),
    osPlatform: 'web',
    deviceType: 'web',
    currPgTitl: route ? route.meta.title : '',
    currPgUrl: window.location.href.split('?')[0]
  };
};
export const getOsPlatformNo = () => {
  const {
    userAgent
  } = navigator;
  let version;
  if (/Windows NT 10.0/.test(userAgent)) {
    version = "Windows 10";
  } else if (/Windows NT 6.2/.test(userAgent)) {
    version = "Windows 8";
  } else if (/Windows NT 6.1/.test(userAgent)) {
    version = "Windows 7";
  } else if (/Mac OS X ([._\d]+)/.test(userAgent)) {
    const match = userAgent.match(/Mac OS X ([._\d]+)/);
    const result = match ? match[1] : '未知版本';
    version = "Mac OS X " + result;
  } else if (/Android ([._\d]+)/.test(userAgent)) {
    const match = userAgent.match(/Android ([._\d]+)/);
    const result = match ? match[1] : '未知版本';
    version = "Android " + result;
  } else if (/iPhone OS ([._\d]+)/.test(userAgent)) {
    const match = userAgent.match(/iPhone OS ([._\d]+)/);
    const result = match ? match[1] : '未知版本';
    version = "iOS " + result.replace(/_/g, '.');
  }
  return version;
};